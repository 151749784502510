.card-list{
    padding-bottom: 10px;
    padding-top: 10px;
    /* position: absolute; */
}

.table{
  position: relative;
}

.parent{
  position: relative;
}

.computer{
  position: absolute;
}


.container-upper {
    overflow: hidden;

  }
  .bg {
    width:100%;
    height: 200px;
    border-radius: 0 0 100% 100%;
    background-color: #0096FF;
    margin-top:-80px;
    position: relative;
  }

  .sign-out{
    position: relative;
    top: 10px;
    right: 10px;
  }

  .bg-image{
    background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp");
            height: 100vh
  }

  html, body {
    max-width: 100%;
    overflow-x: hidden;
  }

  /* 
  https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(11).webp
  https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).webp
  https://mdbootstrap.com/img/Photos/Others/architecture.webp
  https://mdbootstrap.com/img/Photos/Others/img%20%2848%29.webp
  */