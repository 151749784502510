/* body{
    background-color: #D3D3D3;
    font: bold 15px Sans-Serif;
} */

.header{
    background-color: #ADD8E6;
    /* font: bold 14px Montserrat; */
    font-weight: 800;
}
